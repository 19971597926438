import { ActionIcon, Anchor, Badge, Box, Checkbox, createStyles, Group, NativeSelect, Pagination, Paper, ScrollArea, Skeleton, Table, Text, TextInput } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconPalette } from '@tabler/icons';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import { StyleGuideStatusTypes } from 'core/models/StyleGuideModel';
import React, { useEffect, useState } from 'react';

// import { SortableTable } from '../../../../components';
// import CurrencyFormat from 'react-currency-format';
// import { connect } from 'react-redux';

import { useSortBy, useTable, usePagination, useAsyncDebounce, useFilters, useGlobalFilter } from 'react-table';
import { AppConstants } from 'utils/constants';
import { logger } from 'utils/logger';

const useStyles = createStyles((theme, _params, getRef) => ({
    displayFooter: {
        position: 'fixed',
        bottom: 0,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        right: theme.spacing.lg,
        left: 240,
        borderTop: '1px solid',
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[3],
    },
}));

const GuideList = ({ data, onItemClick, onItemSelected, selectedItems, isLoading = false }) => {
    const [items, setItems] = useState(selectedItems);

    const columns = React.useMemo(
        () => [
            // {
            //   Header: 'Item',
            //   columns: [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Description',
                accessor: 'descr',
            },
            {
                Header: 'Code',
                accessor: 'code',
            },
            {
                Header: 'Categories',
                accessor: 'guideCategories',
            },
            // {
            //   Header: '',
            //   accessor: 'action',
            // }
            //   ],
            // },
            // {
            // Header: 'Info',
            // columns: [
            // {
            //   Header: 'Brand',
            //   accessor: 'facet_brand',
            // },
            // {
            //   Header: 'Category',
            //   accessor: 'metadata_toplevelcategory',
            // },
            // {
            //   Header: 'Season',
            //   accessor: 'facet_season',
            // },
            // {
            //   Header: 'Location',
            //   accessor: 'facet_brandcode',
            // },
            //   ],
            // },
        ],
        [],
    );

    const {
        // getTableProps,
        // getTableBodyProps,
        // headerGroups,

        // prepareRow,
        // page, // Instead of using 'rows', we'll use page,
        // // which has only the rows for the active page

        // // The rest of these things are super handy, too ;)
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        // gotoPage,
        // nextPage,
        // previousPage,
        // setPageSize,
        state: { globalFilter },
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: AppConstants.defaultPaginationSize, globalFilter: null },
        },

        useFilters,
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
    );

    /*
    const selectHandler = (elem,selected) => {
      onItemSelected(elem,selected);
    }*/

    useEffect(() => {
        logger.log(' ITEMS SELECTED');
        setItems(selectedItems);
    }, [selectedItems, setItems]);

    return (
        <Box sx={{ width: '100%', overflowX: 'auto', height: '100%' }}>
            <CustomTable columns={columns} data={data} selectedItems={selectedItems} onItemClick={onItemClick} onItemSelected={onItemSelected} isLoading={isLoading}>
                <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
            </CustomTable>
            {/* <SortableTable data={data} showLoading={true} rows={rows} columns={columns} /> */}
        </Box>
    );
};

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
    const count = preGlobalFilteredRows ? preGlobalFilteredRows.length : 0;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 50);

    return (
        <span>
            <TextInput
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
            />
        </span>
    );
};

const CustomTable = ({ columns, data, selectedItems, onItemClick, onItemSelected, isLoading }) => {
    // Use the state and functions returned from useTable to build your UI
    const imgURL =
        'https://media.istockphoto.com/vectors/vector-illustration-of-jacket-isolated-on-white-background-clothing-vector-id1319338185?b=1&k=20&m=1319338185&s=170667a&w=0&h=6ZYN2RP-2NjtQq3CdkzUtFkj4fK9BLW2lV9PyRClEDw=';

    // const [pagination, setPagination] = useState({
    //   pageIndex: 0,
    //   pageSize: 50,
    // })
    const [scrolled, setScrolled] = useState(false);

    const { classes, cx } = useStyles();

    const {
        getTableProps,
        // getTableBodyProps,
        headerGroups,

        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
        rows,
        // The rest of these things are super handy, too ;)
        // canPreviousPage,
        // canNextPage,
        pageOptions,
        // pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        // preGlobalFilteredRows,
        // setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: AppConstants.defaultPaginationSize, globalFilter: null },
        },

        useFilters,
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
    );

    // Render the UI for your table
    return (
        <ScrollArea
            sx={(theme) => ({
                height: '100%',
                // marginRight: -theme.spacing.xl,
                paddingRight: theme.spacing.xl,
                paddingBottom: 20,
            })}
            onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
            {/* verticalSpacing='5' */}
            <Table size='sm' {...getTableProps()} sx={{ marginBottom: 50 }}>
                <thead className={cx('scrollTableHeader', { ['scrolled']: scrolled })}>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    <Group position='apart'>
                                        <Text size='sm'>{column.render('Header')}</Text>
                                        {/* Add a sort direction indicator */}
                                        <Group>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                        <IconChevronDown size={16} />
                                                    </ActionIcon>
                                                ) : (
                                                    <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                        <IconChevronUp size={16} />
                                                    </ActionIcon>
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </Group>
                                    </Group>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                {isLoading ? (
                    <tbody>
                        {[1, 2, 3, 4, 5].map((i) => (
                            <tr key={i}>
                                <td>
                                    <Skeleton height={8} my='xs' />
                                </td>
                                {/* <td onClick={() => onItemClick(element)}>{element.sampleId}</td> */}
                                <td>
                                    {' '}
                                    <Skeleton height={8} radius='xl' />
                                </td>
                                <td>
                                    {' '}
                                    <Skeleton height={8} radius='xl' />
                                </td>

                                <td>
                                    {' '}
                                    <Skeleton height={8} radius='xl' />
                                </td>
                                {/* <td align='right'> <Skeleton height={8} radius="xl" /></td>
            <td align='right'> <Skeleton height={8} radius="xl" /></td> */}
                                {/* <td align='center'> <Skeleton height={8} radius="xl" /></td> */}
                                {/* <td align='right'> <Skeleton height={8} radius="xl" /></td>
            <td align='right'> <Skeleton height={8} radius="xl" /></td>
            <td align='right'> <Skeleton height={8} radius="xl" /></td> */}

                                {/* <td></td> */}
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <tbody>
                        {page.map((row, i) => {
                            prepareRow(row);

                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        let renderCell;
                                        switch (cell.column.id) {
                                            case 'name': {
                                                renderCell = (
                                                    <Group sx={{ minWidth: '230px' }}>
                                                        <Group sx={{ flexShrink: 0 }}>
                                                            <Checkbox
                                                                checked={selectedItems.find((val) => val.id === row.original.id) ? true : false}
                                                                onChange={(e) => onItemSelected(e, row.original, e.currentTarget.checked, rows)}></Checkbox>
                                                            {/* <SampleCheckBox  element={element} onItemSelected={(elem,selected) => selectHandler(elem,selected)} selectedItems={items} /> */}
                                                            <Group onClick={() => onItemClick(row.original)}>
                                                                {/* <Image radius='sm'
                        width={50}
                        height={50}
                        src={imgURL}
                        
                      /> */}
                                                                <IconPalette />
                                                            </Group>
                                                            <Box sx={{ flexShrink: 1 }} onClick={() => onItemClick(row.original)}>
                                                                <Anchor size='sm'>
                                                                    <Text size='sm' lineClamp={1} sx={{ maxWidth: '250px' }}>
                                                                        {row.original.name}
                                                                    </Text>
                                                                </Anchor>
                                                                <Text size='xs' color='dimmed'>
                                                                    {row.original.code}
                                                                </Text>
                                                            </Box>
                                                        </Group>
                                                    </Group>
                                                );
                                                break;
                                            }
                                            case 'descr': {
                                                renderCell = (
                                                    <Text sx={{ minWidth: '200px' }} lineClamp={2} size='sm'>
                                                        {cell.render('Cell')}
                                                    </Text>
                                                );
                                                break;
                                            }
                                            case 'guideCategories': {
                                                renderCell = (
                                                    <Group>
                                                        {row.original.guideCategories.map((val, idx) => {
                                                            return(
                                                            <>
                                                                {val.status && val.status === StyleGuideStatusTypes.ARCHIVED ? (
                                                                    null
                                                                ) : <Badge opacity={val.status && val.status === StyleGuideStatusTypes.ARCHIVED} key={idx}>
                                                                {val.name}
                                                            </Badge>}
                                                            </>);
                                                        })}
                                                    </Group>
                                                );
                                                break;
                                            }

                                            case 'metadata_toplevelcategory': {
                                                renderCell = (
                                                    <div onClick={() => onItemClick(row.original)}>
                                                        <Badge color='violet' sx={{ maxWidth: 150 }}>
                                                            {cell.render('Cell')}
                                                        </Badge>
                                                    </div>
                                                );
                                                break;
                                            }
                                            default: {
                                                renderCell = (
                                                    <div style={{ minWidth: '120px' }}>
                                                        <Text lineClamp={2} size='sm'>
                                                            {cell.render('Cell')}
                                                        </Text>
                                                    </div>
                                                );
                                            }
                                        }
                                        return (
                                            <td {...cell.getCellProps()} valign='top'>
                                                {renderCell}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </Table>

            {!isLoading && data.length === 0 ? <EmptyState /> : null}

            <Paper shadow='sm'>
                <Group className={[classes.displayFooter, 'table-record-footer']} p='sm'>
                    <Pagination size={'sm'} total={pageOptions.length} withEdges page={pageIndex + 1} onChange={(idx) => gotoPage(idx - 1)} />
                    <Group>
                        <Text size='sm'>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </Text>

                        <NativeSelect
                            size='sm'
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                            data={[
                                { value: 20, label: 'Show 20' },
                                { value: 50, label: 'Show 50' },
                                { value: 80, label: 'Show 80' },
                                { value: 100, label: 'Show 100' },
                            ]}></NativeSelect>
                    </Group>
                </Group>
            </Paper>
        </ScrollArea>
    );
};

export default GuideList;

/*
function GuideItemMenu() {
    return (
      <Menu size='lg'>
        <Menu.Item icon={<IconEdit size={14} />}>Edit Guide</Menu.Item>
        <Menu.Item icon={<IconShare size={14} />}>Share Guide</Menu.Item>      
        <Menu.Item color="red" icon={<IconTrash size={14} />} size={14}>Archive Guide</Menu.Item>
      </Menu>
    );
  }
*/
