import {
    ActionIcon,
    Anchor,
    Avatar,
    Badge,
    Box,
    Button,
    Checkbox,
    createStyles,
    Group,
    Menu,
    NativeSelect,
    Pagination,
    Paper,
    ScrollArea,
    Skeleton,
    Table,
    Text,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { IconCamera, IconChecklist, IconChevronDown, IconChevronRight, IconChevronUp, IconDots, IconDragDrop, IconEye, IconShirt, IconSubtask, IconTrash } from '@tabler/icons';
import { CountDisplay } from 'core/components/countDisplay/CountDisplay';
import { PulseIndicator } from 'core/components/index';
import SessionPopover from 'core/components/popovers/SessionPopover';
import TalentPopover from 'core/components/popovers/TalentPopover';
import { SampleEventType, SampleStatusTypes } from 'core/models/SampleModel';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Moment from 'react-moment';
import { Link, useNavigate } from 'react-router-dom';

import { useSortBy, useTable, usePagination, useFilters, useGlobalFilter, useExpanded } from 'react-table';
import { DataFormats } from 'utils/constants';
import { getStyleStatusColor, getUserAvatarColour, getUserInitials, sampleAlphanumericFilter } from 'utils/helper';
import { logger } from 'utils/logger';

const useStyles = createStyles((theme, _params, getRef) => ({
    displayFooter: {
        position: 'fixed',
        bottom: 0,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        right: theme.spacing.lg,
        left: 240,
        borderTop: '1px solid',
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[3],
    },
}));

const SampleList = ({
    data,
    onItemClick,
    onMenuItemClick,
    onStatusClick,
    onItemSelected,
    selectedItems,
    filter,
    actionMenu,
    columns,
    rowMenuItems,
    showPopover = false,
    allowAssignCapture = false,
    totalCount = 0,
    loading = false,
    paginationSize = 100,
    setFilter,
}) => {
    // const [uirows,setRows] = useState([]);
    const [items, setItems] = useState(selectedItems);
    const cols = React.useMemo(() => {
        return columns
            ? columns
            : [
                  {
                      // Build our expander column
                      id: 'expander', // Make sure it has an ID
                      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                          <>
                              {filter && filter.groupBy && filter.groupBy.length ? (
                                  <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? <IconChevronDown /> : <IconChevronRight />}</span>
                              ) : null}
                          </>
                      ),
                      Cell: ({ row }) =>
                          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                          // to build the toggle for expanding a row
                          row.canExpand ? (
                              <span
                                  {...row.getToggleRowExpandedProps({
                                      style: {
                                          // We can even use the row.depth property
                                          // and paddingLeft to indicate the depth
                                          // of the row
                                          paddingLeft: `${row.depth * 2}rem`,
                                      },
                                  })}>
                                  {row.isExpanded ? <IconChevronDown /> : <IconChevronRight />}
                              </span>
                          ) : null,
                  },
                  {
                      Header: 'Photo Code',
                      accessor: 'photoCode',
                      sortType: 'alphanumeric',
                      width: 200,
                  },
                  // {
                  //   Header: 'Item',
                  //   columns: [
                  {
                      Header: 'Style',
                      accessor: 'stylenumber',
                      sortType: 'alphanumeric',
                      width: 80,
                  },
                  {
                      Header: 'Status',
                      accessor: 'styleCount',
                      sortType: 'alphanumeric',
                  },
                  {
                      Header: 'Name',
                      accessor: 'name',
                      sortType: 'alphanumeric',
                      width: 200,
                  },

                  {
                      Header: 'Description',
                      accessor: 'descr',
                      sortType: 'alphanumeric',
                  },

                  {
                      Header: 'Size',
                      accessor: 'size',
                      sortType: 'alphanumeric',
                      width: 80,
                  },

                  {
                      Header: 'OTB Date',
                      accessor: 'otbDate',
                      sortType: 'alphanumeric',
                      width: 80,
                  },

                  {
                      Header: 'Talent',
                      accessor: 'talentCodes',
                      sortType: 'alphanumeric',
                      width: 80,
                  },

                  {
                      Header: 'Department',
                      accessor: 'departmentStr',
                      sortType: 'alphanumeric',
                      width: 120,
                  },
                  {
                      Header: '',
                      accessor: 'action',
                      width: 30,
                  },
              ];
    }, [filter.groupBy, data, columns]);

    useEffect(() => {
        setItems(selectedItems);
    }, [selectedItems, setItems]);

    const tableProps = {
        columns: cols,
        data,
        selectedItems,
        onItemClick,
        showPopover,
        actionMenu,
        onItemSelected,
        onMenuItemClick,
        onStatusClick,
        allowAssignCapture,
        rowMenuItems,
        totalCount,
        loading,
        paginationSize,
        filter,
        setFilter,
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'hidden', height: '100%' }}>
            <CustomTable {...tableProps} />
        </Box>
    );
};

const CustomTable = ({
    columns,
    data,
    selectedItems,
    onItemClick,
    onMenuItemClick,
    onItemSelected,
    onStatusClick,
    actionMenu,
    rowMenuItems,
    showPopover,
    allowAssignCapture,
    totalCount,
    loading,
    paginationSize,
    filter,
    setFilter,
}) => {
    // Use the state and functions returned from useTable to build your UI
    const { classes, cx } = useStyles();
    const [scrolled, setScrolled] = useState(false);
    const [navOpen] = useLocalStorage({ key: 'navOpen' });

    const {
        getTableProps,
        // getTableBodyProps,
        headerGroups,

        prepareRow,
        rows,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        // canPreviousPage,
        // canNextPage,
        pageOptions,
        // pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        setPageSize,
        state: {
            pageIndex,
            pageSize,
            sortBy,
            // globalFilter
        },
        // preGlobalFilteredRows,
        // setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: filter.pageIndex,
                pageSize: paginationSize,
                globalFilter: null,
                expanded: false,
                // sortBy: [
                //     {
                //         id: columns[1].accessor,
                //         desc: false,
                //     },
                // ],
                sortBy: filter.sortBy
                    ? [filter.sortBy]
                    : [
                          {
                              id: columns[1].accessor,
                              desc: false,
                          },
                      ],
            },
            sortTypes: {
                alphanumeric: React.useCallback((row1, row2, columnName) => sampleAlphanumericFilter(row1, row2, columnName, true), []),
            },
        },

        useFilters,
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        useExpanded,
        usePagination,
    );

    // Store new sort state in fitler reducer
    useEffect(() => {
        // onChangeSort(sortBy);
        if (sortBy && sortBy.length) {
            const sortItem = sortBy[0];
            if ((filter.sortBy && filter.sortBy.id !== sortItem.id) || !filter.sortBy) {
                setFilter({ ...filter, sortBy: sortItem });
            }
        }
    }, [sortBy]);

    useEffect(() => {
        if (pageIndex >= pageOptions.length) {
            setTimeout(() => gotoPage(pageOptions.length ? pageOptions.length - 1 : 0), 10);
        }
    }, [page]);

    const getPanelWidth = useMemo(() => {
        let width = '100%';
        const element = document.getElementById('nav-wrapper');
        logger.log(element);
        if (element) {
            // Get the computed style of the element
            // const computedStyle = window.getComputedStyle(element);
            // Get the marginLeft property from the computed style
            // const navWidth = computedStyle.getPropertyValue('width');

            // Parse the margin value to get a numeric value (remove 'px' if present)
            // const navWidthValue = parseFloat(navWidth);

            width = navOpen || navOpen === undefined ? `calc(100vw - ${220 + 30 - 10}px)` : `calc(100vw - ${68 + 30 - 10}px)`;
        }

        return width;
    }, [navOpen, window.innerWidth]);

    return (
        <ScrollArea
            sx={(theme) => ({
                height: '100%',
                width: getPanelWidth,
                // marginRight: -theme.spacing.xl,
                paddingRight: theme.spacing.xl,
                paddingBottom: theme.spacing.sm,
            })}
            onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
            {/* <Box sx={(theme) =>({height:'100%', width:getPanelWidth, marginRight: -theme.spacing.xl, paddingRight: theme.spacing.xl,
             paddingBottom:50})}> */}

            <div className='h-scroll-table-container' style={{ height: '100%' }}>
                <Table highlightOnHover size='sm' {...getTableProps()} sx={{ marginBottom: 10 }} className='h-scroll-table'>
                    <thead className={cx('scrollTableHeader', { ['scrolled']: scrolled })}>
                        {headerGroups.map((headerGroup, idx) => {
                            const { key, ...headerProps } = { ...headerGroup.getHeaderGroupProps() };
                            return (
                                <tr key={idx} {...headerProps}>
                                    {headerGroup.headers.map((column, idx) => {
                                        const headerWidth = column.id === 'expander' ? 'auto' : column.width;
                                        const { key, ...colProps } = column.getHeaderProps(column.getSortByToggleProps());
                                        return (
                                            // Add the sorting props to control sorting. For this example
                                            // we can add them into the header props
                                            <th key={idx} {...colProps}>
                                                <Group spacing={'xs'} style={{ width: headerWidth }} position='apart'>
                                                    <Text size='sm'>{column.render('Header')}</Text>
                                                    {/* Add a sort direction indicator */}

                                                    {column.id !== 'expander' ? (
                                                        <Group spacing={0}>
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                                        <IconChevronDown size={16} />
                                                                    </ActionIcon>
                                                                ) : (
                                                                    <ActionIcon color='brand' size='xs' radius='xl' variant='subtle'>
                                                                        <IconChevronUp size={16} />
                                                                    </ActionIcon>
                                                                )
                                                            ) : (
                                                                ' '
                                                            )}
                                                        </Group>
                                                    ) : null}
                                                </Group>
                                            </th>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </thead>

                    {loading ? (
                        <tbody>
                            {[1, 2, 3, 4, 5].map((i) => (
                                <tr key={i}>
                                    {columns.map((col, idx) => (
                                        <td key={idx}>
                                            <Skeleton height={8} my='sm' />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            {page.map((row, idx) => {
                                // logger.log('rowkey', key)
                                prepareRow(row);

                                const rowProps = {
                                    row,
                                    selectedItems,
                                    onItemClick,
                                    onMenuItemClick,
                                    onItemSelected: (evt, original, isChecked) => {
                                        onItemSelected(evt, original, isChecked, rows);
                                    },
                                    onStatusClick,
                                    actionMenu,
                                    showPopover,
                                    allowAssignCapture,
                                };

                                return <TableRow key={idx} rowId={idx} {...rowProps} />;
                            })}
                        </tbody>
                    )}
                </Table>
            </div>
            <Paper shadow='sm'>
                <Group position='' className={[classes.displayFooter, 'pagination-footer']} p='sm'>
                    {/* <Text size='sm'>
                        Total{' '}
                        <strong>
                            {data.length} of {totalCount}
                        </strong>{' '}
                    </Text> */}

                    <Group>
                        <Pagination
                            size={'sm'}
                            total={pageOptions.length}
                            withEdges
                            page={pageIndex + 1}
                            onChange={(idx) => {
                                gotoPage(idx - 1);
                                setFilter({ ...filter, pageIndex: idx - 1 });
                            }}
                        />
                        <Group>
                            <Text size='sm'>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </Text>

                            <NativeSelect
                                size='sm'
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                                data={[
                                    { value: 20, label: 'Show 20' },
                                    { value: 50, label: 'Show 50' },
                                    { value: 80, label: 'Show 80' },
                                    { value: 100, label: 'Show 100' },
                                    { value: 200, label: 'Show 200' },
                                ]}></NativeSelect>
                        </Group>
                    </Group>
                </Group>
            </Paper>
            {/* <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div> */}
            {/* </div>
        </ScrollArea>
        */}

            {/* </Box> */}
        </ScrollArea>
    );
};

export default SampleList;

const TableRow = ({ row, rowId, selectedItems, onItemClick, onMenuItemClick, onStatusClick, onItemSelected, actionMenu, allowAssignCapture = false }) => {
    const theme = useMantineTheme();
    const props = { row, selectedItems, onItemClick, onMenuItemClick, onStatusClick, onItemSelected, actionMenu, allowAssignCapture };
    const navigate = useNavigate();
    const { key, ...rowProps } = row.getRowProps();

    return (
        <tr key={rowId} {...rowProps} className={row.canExpand ? 'can-expand' : ''}>
            {row.cells.map((cell, cellIdx) => {
                let renderCell;
                const value = cell.column.id;

                let checked = selectedItems.find((val) => val.id === row.original.id) ? true : false;

                switch (true) {
                    case value.includes('expander'): {
                        renderCell = <Group sx={{ maxWidth: row.canExpand ? 60 : 0, minWidth: row.canExpand ? 60 : 0 }}>{cell.render('Cell')}</Group>;
                        break;
                    }
                    case value.includes('seasonSort'): {
                        renderCell = <Anchor onClick={() => onItemClick(row.original)}>{cell.render('Cell')}</Anchor>;
                        break;
                    }
                    case value.includes('styleNumber'):
                    case value.includes('stylenumber'): {
                        renderCell = (
                            <Link className='router-link' to={`/warehouse/styles/${cell.value}`}>
                                {cell.render('Cell')}
                            </Link>
                        );
                        break;
                    }

                    case value.includes('seasonCode'): {
                        renderCell = (
                            <Link className='router-link' to={`/seasons/${cell.value}`}>
                                {cell.render('Cell')}
                            </Link>
                        );
                        break;
                    }

                    case value.includes('sessionCode'): {
                        renderCell = (
                            <Link className='router-link' to={`/sessions/${cell.value}`}>
                                {cell.render('Cell')}
                            </Link>
                        );
                        break;
                    }
                    // case value.includes('photographyCount'):
                    case value.includes('styleCount'): {
                        renderCell = !cell.row.canExpand ? (
                            <Group spacing={'xs'} sx={{ height: '100%', flexShrink: 1 }}>
                                <Group noWrap spacing={'xs'}>
                                    {CountDisplay({
                                        val: parseInt(row.original['sampleCount']),
                                        icon: <IconShirt size={18} strokeWidth={1} />,
                                        tooltip: 'Sample Acquired',
                                        theme,
                                        onClick: () => onStatusClick(SampleStatusTypes.SAMPLE_SCAN_COUNT, row.original),
                                    })}
                                    {CountDisplay({
                                        val: row.original['photographyCount'],
                                        icon: <IconCamera size={18} strokeWidth={1} />,
                                        tooltip: 'Photography Count',
                                        onClick: () => onStatusClick(SampleStatusTypes.SAMPLE_PHOTOGRAPHY_COUNT, row.original),
                                        theme,
                                    })}
                                </Group>
                                <Group noWrap spacing={'xs'}>
                                    {CountDisplay({
                                        val: row.original['taskCount'],
                                        icon: <IconSubtask size={18} strokeWidth={1} />,
                                        tooltip: 'Total Tasks',
                                        onClick: () => onStatusClick(SampleStatusTypes.SAMPLE_TASK_COUNT, row.original),
                                        theme,
                                    })}
                                    {CountDisplay({ val: row.original['taskCompleteCount'], icon: <IconChecklist size={18} strokeWidth={1} />, tooltip: 'Tasks Completed', theme })}
                                </Group>
                            </Group>
                        ) : null;
                        break;
                    }
                    case value.includes('otbDate'):
                    case value.includes('distributionDate'):
                    case value.includes('completed'): {
                        renderCell = (
                            <Group sx={{ width: 120 }}>
                                {!row.canExpand ? (
                                    <Text size='sm'>
                                        {row.original[value] && row.original[value].length ? <Moment date={row.original[value]} parse='YYYY-MM-DD-hh-mm-ss' format='DD/MM/YYYY' trim /> : ''}
                                    </Text>
                                ) : null}
                            </Group>
                        );
                        break;
                    }
                    case value.includes('status'): {
                        renderCell = <div onClick={() => onItemClick(row.original)}>{cell.value ? <Badge color={getStyleStatusColor(cell.value)}>{cell.value}</Badge> : null}</div>;
                        break;
                    }
                    case value.includes('instructions["Web Photography Code"]'):
                    case value.includes('photoCode'):
                    case value.includes('targetSort'): {
                        if (row.original.colours) {

                            renderCell = (
                                <Group sx={{ minWidth: '130px', width: '100%' }}>
                                    <Group sx={{ flexShrink: 0, flexGrow: 1 }}>
                                        <Checkbox
                                            checked={selectedItems.find((val) => val.id === row.original.id) ? true : false}
                                            onChange={(e) => onItemSelected(e, row.original, e.currentTarget.checked)}></Checkbox>
                                        {/* <SampleCheckBox  element={element} onItemSelected={(elem,selected) => selectHandler(elem,selected)} selectedItems={items} /> */}
                                        {/* <Group onClick={() => onItemClick(row.original)}> */}
                                        {/* <Image radius='sm' width={50} height={50} src={imgURL} /> */}
                                        {/* <IconShirt /> */}
                                        {/* </Group> */}
                                        {/* <Box sx={{ flexShrink: 1 }} onClick={() => onItemClick(row.original)}> */}
                                        <Box sx={{ flexShrink: 0, flexGrow: 1 }}>
                                            {/* <SamplePopover
                        data={{ targetSort: row.original.targetSort }}> */}
                                            {/* <SamplePopover key={row.original[cell.column.id]} data={{ targetSort: row.original[cell.column.id] }}> */}
                                            {/* <Anchor size='sm'> */}
                                            <Group position='apart'>
                                                <Text size='sm' lineClamp={1}>
                                                    {row.original[cell.column.id]}
                                                </Text>
                                                
                                                {row.original.liveData && row.original.liveData.files && row.original.liveData.files.length ? (
                                                    <Tooltip position={rowId === 0?'bottom':'top'} label={`${moment(row.original.liveData.timestamps.min).format(DataFormats.TIME_FORMAT)} → ${moment(row.original.liveData.timestamps.max).format(DataFormats.TIME_FORMAT)}`}>
                                                        {/* <PulseIndicator /> */}
                                                        <Box><PulseIndicator /></Box>
                                                    </Tooltip>
                                                ) : null}
                                            </Group>
                                            {/* </Anchor> */}
                                            {/* </SamplePopover> */}

                                            {/* <Text sx={{ maxWidth: 600 }} lineClamp={2} size='sm'>
                                                {row.original.name}
                                            </Text> */}

                                            {/* </SamplePopover> */}
                                            {/* <Text size='xs' color='dimmed'>
                        {row.original.parentCode}
                      </Text>
                      <Text sx={{ maxWidth: 600 }} lineClamp={2} size='sm'>
                        {row.original.descr}
                      </Text> */}
                                        </Box>
                                    </Group>
                                    {/* {row.original.showIndicator?
                                    <div style={{position:'absolute', top:0, right:0}}>
                                    <LiveIndicator />
                                    </div>
                                    :null} */}
                                </Group>
                            );
                        } else {
                            renderCell = cell.row.canExpand ? (
                                <Group sx={{ minWidth: '50px' }}>
                                    <Group sx={{ flexShrink: 0 }}>
                                        {/* <Checkbox
                                            checked={selectedItems.find((val) => val.id === row.original.id) ? true : false}
                                            onChange={(e) => onItemSelected(e, row.original, e.currentTarget.checked)}></Checkbox> */}
                                        <Group sx={{ flexShrink: 1 }}>
                                            {/* {value.includes('instructions["Web Photography Code"]') ? (
                                                <Text size='sm' lineClamp={1} sx={{ maxWidth: '350px' }}>
                                                    {row.original.instructions['Web Photography Code']}
                                                </Text>
                                            ) : (
                                                <Text size='sm' lineClamp={1} sx={{ maxWidth: '350px' }}>
                                                    {row.original[cell.column.id]}
                                                </Text>
                                            )} */}
                                            {row.original.id}
                                            {row.original.subRows?.length ? <Badge color={'brand'}>{row.original.subRows?.length}</Badge> : null}
                                        </Group>
                                    </Group>
                                </Group>
                            ) : (
                                <Group sx={{ minWidth: '50px' }}>
                                    <Group sx={{ flexShrink: 0 }}>
                                        <Checkbox
                                            checked={selectedItems.find((val) => val.id === row.original.id) ? true : false}
                                            onChange={(e) => onItemSelected(e, row.original, e.currentTarget.checked)}></Checkbox>
                                        <Group sx={{ flexShrink: 1 }}>{row.original.instructions ? row.original.instructions['Web Photography Code'] : ''}</Group>
                                    </Group>
                                </Group>
                            );
                        }

                        break;
                    }
                    case value.includes('descr'):
                    case value.includes('description'): {
                        renderCell = (
                            <Text sx={{ maxWidth: 600, minWidth: 200 }} lineClamp={2} size='sm'>
                                {row.original.descr}
                            </Text>
                        );
                        break;
                    }
                    case value.includes('instructions["Web Stock OTB Date"]'):
                    case value.includes('otbDate'): {
                        renderCell = (
                            <Box sx={{ maxWidth: 200, minWidth: 100 }}>
                                {/* <Moment format={DataFormats.DATE_FORMAT_SHORT}>{cell.render('Cell')}</Moment> */}
                                {value.includes('instructions["Web Stock OTB Date"]') ? (
                                    <Moment format={DataFormats.DATE_FORMAT_SHORT}>{row.original.instructions['Web Stock OTB Date']}</Moment>
                                ) : (
                                    cell.render('Cell')
                                )}
                            </Box>
                        );
                        break;
                    }

                    case value.includes('metadata_toplevelcategory'): {
                        renderCell = (
                            <div onClick={() => onItemClick(row.original)}>
                                <Badge color='violet' sx={{ maxWidth: 150 }}>
                                    {cell.render('Cell')}
                                </Badge>
                            </div>
                        );
                        break;
                    }

                    case value.includes('instructions'): {
                        //const keys = Object.keys(row.original.instructions);
                        const regex = /"(.*?)"/; // Regular expression to match the value inside double quotes

                        const match = cell.column.id.match(regex);
                        let key = match ? match[0] : '';
                        key = key.replace(/"/g, '');

                        renderCell = (
                            <div onClick={() => onItemClick(row.original)} style={{ minWidth: 80 }}>
                                {key === 'department' ? `${row.original.instructions['Dept']} - ` : ''}
                                {row.original.instructions ? row.original.instructions[`${key}`] : ''}

                                {/* <Table striped>
                  <tbody>
                  {keys.map((key,idx) => {
                    return (<tr key={idx}>
                      <td style={{width:220}}><Text pl={'sm'} color='dimmed' mr={'md'}>{key}: </Text></td>
                      <td>{row.original.instructions[key]}</td>
                    </tr>)
                  })}
                  </tbody>
                </Table> */}
                            </div>
                        );
                        break;
                    }

                    case value.includes('issuer'): {
                        const userInitials = row.original[value] ? getUserInitials(row.original[value].split(' ')[0], row.original[value].split(' ')[1]) : '';
                        const themeCol = getUserAvatarColour(userInitials);

                        renderCell = (
                            <>
                                {row.original[value] ? (
                                    <Group noWrap>
                                        <Avatar variant='filled' title={`${row.original[value]}`} color={themeCol} size={30} radius={40}>
                                            {row.original[value].charAt(0)}
                                        </Avatar>
                                        <Text>{row.original[value]}</Text>
                                    </Group>
                                ) : null}
                            </>
                        );
                        break;
                    }

                    case value.includes('action'): {
                        // const menuItems = rowMenuItems?rowMenuItems:null;
                        renderCell = cell.row.canExpand ? (
                            <></>
                        ) : (
                            <Group
                                sx={{
                                    width: '100%',
                                    alignContent: 'center',
                                    justifyContent: 'end',
                                    maxWidth: allowAssignCapture ? 'auto' : 'auto',
                                    minWidth: allowAssignCapture ? '250px' : 'auto',
                                }}>
                                {/* {actionMenu ? actionMenu : null} */}
                                {allowAssignCapture && row.original.photographyCount ? (
                                    <Button variant='outline' size='sm' onClick={(e) => onMenuItemClick({ event: e, type: SampleEventType.SAMPLE_ASSIGN_CAPTURES, data: row.original })}>
                                        Style Guide Selections
                                    </Button>
                                ) : null}

                                <SampleItemMenu {...props} item={row.original} menuItems={actionMenu} />
                                {/* <SampleItemMenu onMenuItemClick={(e) => onMenuClick(e,row.original)}/> */}
                            </Group>
                        );
                        break;
                    }
                    case value.includes('studioSessionCodes'): {
                        renderCell = (
                            <Box style={{ minWidth: 140 }}>
                                {row.original.studioSessionCodes?.map((val,idx) => (
                                    <div key={idx} style={{display:'inline-block'}}> 
                                    <SessionPopover onShowDetails={() => {}} data={{ ssPart:val }}>
                                        <Link to={`/sessions/${val}`} className='router-link'>
                                            {val}
                                            
                                        </Link>
                                        </SessionPopover>
                                    </div>
                                ))}
                            </Box>
                        );
                        break;
                    }

                    case value.includes('talentCodes'): {
                        renderCell = (
                            <>
                                <Avatar.Group spacing='sm' style={{ paddingLeft: 0 }}>
                                    {row.original['talents']?.map((talent, idx) => {
                                        const userInitials = talent.fullName ? getUserInitials(talent.fullName.split(' ')[0], talent.fullName.split(' ')[1]) : '';
                                        const themeCol = getUserAvatarColour(userInitials);
                                        const margin = idx ? -10 : 0;
                                        return (
                                            <TalentPopover key={idx} data={talent} onShowDetails={() => navigate(`/talent/${talent.code}`)}>
                                                <Avatar
                                                    key={idx}
                                                    variant='gradient'
                                                    component={Link}
                                                    to={`/talent/${talent.code}`}
                                                    src={talent.latestURL}
                                                    sx={{ marginLeft: margin }}
                                                    title={`${talent.fullName}`}
                                                    color={themeCol}
                                                    size={25}
                                                    radius={40}>
                                                    {talent.fullName?.charAt(0)}
                                                </Avatar>
                                            </TalentPopover>
                                        );
                                    })}
                                </Avatar.Group>
                            </>
                        );
                        break;
                    }

                    default: {
                        renderCell = cell.row.canExpand ? (
                            <></>
                        ) : (
                            <div style={{ minWidth: 80 }}>
                                <Text lineClamp={2} size='sm'>
                                    {cell.render('Cell')}
                                </Text>
                            </div>
                        );
                    }
                }
                const { key, ...cellProps } = cell.getCellProps();
                return (
                    <td key={cellIdx} {...cellProps} style={{ verticalAlign: 'top' }} className={checked ? 'cellChecked' : ''}>
                        {/* <Group sx={{minWidth:150}}> */}
                        {renderCell}
                        {/* </Group> */}
                    </td>
                );
            })}
        </tr>
    );
};

const SampleItemMenu = (props) => {
    const defaultMenu = [
        {
            handler: () =>
                props.onMenuItemClick({
                    type: SampleEventType.SAMPLE_VIEW,
                    data: props.item,
                }),
            icon: <IconEye size={14} />,
            label: 'View Sample',
        },
        {
            handler: () =>
                props.onMenuItemClick({
                    type: SampleEventType.SAMPLE_ASSIGN_CAPTURES,
                    data: props.item,
                }),
            icon: <IconDragDrop size={14} />,
            size: 14,
            label: 'Style Guide Selections',
        },
        {
            handler: () =>
                props.onMenuItemClick({
                    type: SampleEventType.SAMPLE_DELETE,
                    data: props.item,
                }),
            color: 'red',
            icon: <IconTrash size={14} />,
            size: 14,
            label: 'Unassign Sample',
        },
    ];

    const menuItems = props.actionMenu ? props.actionMenu : defaultMenu;

    return (
        <Menu position='bottom-end'>
            <Menu.Target>
                <ActionIcon>
                    <IconDots />
                </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
                {menuItems.map((val, idx) => (
                    <Menu.Item key={idx} onClick={() => val.handler(props.item)} icon={val.icon}>
                        {val.label}
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
};
