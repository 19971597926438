export const AppConstants = {
    inputVariant:'default',
    defaultPaginationSize:100,
}

export const SampleActions = {
    BUILD_OUTFIT:'buildoutfit'
}
export const SessionActions = {
    BUILD_OUTFIT:'buildoutfit',
    CREATE_SESSION_FROM_SAMPLES:'createSessionFromSamples',
    ASSIGN_SAMPLES_TO_SESSION:'assignSamplesToSession',
    ASSIGN_STYLEGUIDE:'assignStyleGuide',
    REMOVE_STYLEGUIDE:'removeStyleGuide',
    ASSIGN_SAMPLE:'sessionAssignSample',
    ASSIGN_TALENT_TO_SAMPLES:'assignTalentToSamples',
    REMOVE_SAMPLE:'sessionRemoveSample',
    ASSIGN_CAPTURES_TO_SAMPLES:'sessionAssignCapturesToSamples',
    DOWNLOAD_PDF_STUB:'sessionDownloadPDFStub',
    ARCHIVE:'sessionArchive',

}

export const GuideActions = {
    EDIT_GUIDE_CAT:'guideCategoryEdit',
    ADD_GUIDE_CAT_SHOT:'guideCategoryAddShot',
    UPDATE_GUIDE_CAT:'guideCategoryUpdate',
    REMOVE_GUIDE_CAT:'guideCategoryRemove',
    ARCHIVE_GUIDE_CAT:'guideCategoryArchive',
    UNARCHIVE_GUIDE_CAT:'guideCategoryUnArchive',
    // EDIT_GUIDE:'buildoutfit'
}
export const SeasonActions = {
    // EDIT_GUIDE:'buildoutfit'
}

export const Exceptions = {
  SERIALISATION:'com.amazon.coral.service#SerializationException'
}

export const TaskActions = {
    EDIT_TASK:'editTask',
    DELETE_TASK:'deleteTask',
    ADD_TASK:'addTask'
}

export const TalentActions = {
  EDIT_TALENT:'editTalent',
  DELETE_TALENT:'deleteTalent',
  ADD_TALENT:'addTalent'
}

export const FrameActions = {
    ADD_SAMPLE:'add_sample',
    CLEAR_FRAME:'clear_frame',
    DELETE_FRAME:'delete_frame',
    CLOSE:'close',
    ADD_FRAME:'add_frame',
    SAMPLE_CLICK:'sample_click',
    DELETE_SAMPLE:'delete_sample',
    REPLACE_SAMPLE:'replace_sample',
  }

  

export const SETTINGS = {
    TOOLBAR_HEIGHT:60,
    TOOLBAR_WIDTH:60,
}
export const ItemTypes = {
    FRAME: 'frame',
    BOX:'box',
    CAPTURE:'capture',
}

export const ApprovalLayoutTypes = {
    GRID: 'grid',
    SINGLE:'single',
    COMPARE:'compare',
    COMMENT:'comment',
}

export const ApprovalImageToolbarModes = {
    ZOOM_IN: 'zoomIn',
    ZOOM_OUT:'zoomOut',
    ZOOM_RESET:'zoomReset',
    MARKUP:'markup',
}

export const FlagRating = {
    FLAG_SELECT: '1',
    FLAG_KILL:'2',
}

export const DataFormats = {
    DATE_FORMAT: 'MMMM Do YYYY',
    DATE_FORMAT_SHORT: 'DD/MM/YYYY',
    DATE_FORMAT_REST: 'YYYY-MM-DD',
    TIMEZONE:'Australia/Melbourne',
    DATE_FORMAT_CREATE_PARSE:'YYYY-MM-DD-hh-mm-ss',
    TIME_FORMAT:'hh:mm A'
}

export const SessionTypes = {
    UNKOWN:"Session_Unknown",
}

export const ViewModes = {
    EDIT: 'viewEdit',
    VIEW: 'viewView',
    ADD: 'viewAdd',
}
export const TreatmentTypes = {
    POST_PRODUCTION:{label:'Post Production', value:'Post Production'},
    RETOUCHING:{label:'Retouching', value:'Retouching'}
}


export const PaginationValues = [{value:20,label:'Show 20'},{value:50,label:'Show 50'}, {value:80,label:'Show 80'}, {value:100,label:'Show 100'}];

export const APP_ENV = process.env.NODE_ENV === 'development' ? 'development' : 'production';
export const LOG_LEVEL = APP_ENV === 'production' ? 'warn' : 'log';

export const ResponseCodes = {
  ABORT_ERROR: 20
}
export const SHELL_TAB_BAR_HEIGHT = 65;

export const ObjectKeys = ['S','N','L'];

export const StatusTypes = {
    PENDING: 'Pending',
    ASSIGNED: 'Assigned',
    IN_APPROVAL: 'In Approval',
    COMPLETE:'Complete',
    CANCELLED:'Cancelled'
}

export const StatusTypeArray = [StatusTypes.PENDING, StatusTypes.ASSIGNED, StatusTypes.IN_APPROVAL, StatusTypes.COMPLETE, StatusTypes.CANCELLED];

const colors = {
    Y50:'#70575A',
    B50:'#4C616D',
    G50:'#505050',
    P50:'#3F5654',
    N400A:'grey'
  }

  export const status_unassigned = {
    id: '0',
    name: 'Unassigned',
    label: 'Unassigned',
    value:'unassigned',
    url: '',
    avatarUrl: null,
    colors: {
      soft: colors.Y50,
      hard: colors.N400A,
      primary:'grey'
    },
  };

  export const status_backlog = {
    id: '1',
    name: 'Pending',
    label: 'Pending',
    value:'pending',
    url: '',
    avatarUrl: null,
    colors: {
      soft: colors.Y50,
      hard: colors.N400A,
      primary:'grey'
    },
  };
  
  export const status_selected = {
    id: '2',
    name: 'Assigned',
    label: 'Assigned',
    value:'assigned',
    url: '',
    avatarUrl: null,
    colors: {
      soft: colors.G50,
      hard: colors.N400A,
      primary:'violet'
    },
  };
  
  export const status_progress = {
    id: '3',
    name: 'In Approval',
    label: 'In Approval',
    value:'inapproval',
    url: '',
    avatarUrl: null,
    colors: {
      soft: colors.B50,
      hard: colors.N400A,
      primary:'blue'
    },
  };
  
  export const status_complete = {
    id: '4',
    name: 'Complete',
    label: 'Complete',
    value:'complete',
    url: '',
    avatarUrl: null,
    colors: {
      soft: colors.P50,
      hard: colors.N400A,
      primary:'teal'
    },
  };
  
  export const status_canceled = {
    id: '5',
    name: 'Cancelled',
    label: 'Cancelled',
    value:'cancelled',
    url: '',
    avatarUrl: null,
    colors: {
      soft: colors.P50,
      hard: colors.N400A,
      primary:'red'
    },
  };
  
  export const statuss = [status_backlog, status_selected, status_progress, status_complete, status_canceled];

  export const PHOTOSHOP_FILE_TYPES = ".psd,.jpg,.png";

  export const colorThemes = [
    "light",
    "dark",
    "blue",
    "cyan",
    "gray",
    "indigo",
    "lime",
    "orange",
    "pink",
    "purple",
    "red",
    "teal",
    "yellow"
  ];
  
  export const userRoles = [
    { label: "Sample Co-ordinator", value: "Sample Co-ordinator" },
    { label: "Art Director", value: "Art Director" },
    { label: "Producer", value: "Producer" },
    { label: "Photographer", value: "Photographer" },
    { label: "Retoucher", value: "Retoucher" },
    { label: "Stylist", value: "Stylist" },
  ];

  export const shotChannels = [
    { label: 'Packaging', value: 'Packaging' },
    { label: 'e-commerce', value: 'e-commerce' },
    { label: 'Social', value: 'Social' },
    { label: 'Campaign', value: 'Campaign' }
  ]

  export const shotTags = [
    { label: 'Hero', value: 'Hero' },
    { label: 'Hover', value: 'Hover' },
  ]

  export const ScanStatusItems = [
    { label: 'Pending', value: 'PENDING' },
    { label: 'Succeeded', value: 'SUCCEEDED' },
    { label: 'Failure', value: 'FAILURE' },
    { label: 'Abandoned', value: 'ABANDONED' },
    { label: 'Created', value: 'CREATED' },
    { label: 'Parsed', value: 'PARSED' },
    { label: 'Master record not found', value: 'MASTER_NOT_FOUND' },
    { label: 'A2 Record not found', value: 'LOCAL_NOT_FOUND' },
  ]

  export const ScanStatuses = {
    PENDING: { label: 'Pending', value: 'PENDING' },
    SUCCEEDED: { label: 'Succeeded', value: 'SUCCEEDED' },
    FAILURE: { label: 'Failure', value: 'FAILURE' },
    ABANDONED: { label: 'Abandoned', value: 'ABANDONED' },
    CREATED: { label: 'Created', value: 'CREATED' },
    PARSED: { label: 'Parsed', value: 'PARSED' },
    MASTER_NOT_FOUND: { label: 'Master record not found', value: 'MASTER_NOT_FOUND' },
    LOCAL_NOT_FOUND: { label: 'A2 Record not found', value: 'LOCAL_NOT_FOUND' }
  }


  export const Genders = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: '-', value: ' ' }
  ]

  export const dataViewSettings = {
    defaultPaginationSize:100
  }

  export const SampleGrades = [
    { label: 'Satisfactory', value: 'Satisfactory' },
    { label: 'Damaged', value: 'Damaged' },
  ]
